import React, { useState } from "react";
import { Modal, Navbar } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import { useAccount, useSignMessage } from "wagmi";
import { useNavigate } from "react-router-dom";
import { BsXCircleFill, BsArrowRight } from "react-icons/bs";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { toast } from "react-toastify";
import { useWeb3Modal } from "@web3modal/react";

import { cutAddress } from "../utils/common";
import ArrowRight from "../assets/images/arrow.svg";
import { setCurrentFormStep } from "../store/reducer";
import { useAuth } from "../hooks/useAuth";

const Header = ({ isConfirmBack = false }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { login } = useAuth();
  const { address, status, isConnected } = useAccount();
  const { open } = useWeb3Modal();
  const [show, setShow] = useState(false);

  let user = localStorage.getItem("user");
  if (user) {
    user = JSON.parse(user);
  }

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const goToHome = () => {
    if (isConfirmBack) {
      handleShow();
    } else {
      navigate("/manage");
    }
  };

  const { signMessage } = useSignMessage({
    onSuccess(data, variables) {
      const payload = {
        message: variables.message,
        signature: data,
        wallet_address: address,
      };
      if (payload) {
        login(payload, pathname);
      }
    },
    onError() {
      toast.error("Hey, just need to verify it’s you. Please authenticate to proceed.");
    },
  });

  const metamaskHandler = async () => {
    if (status === "connected") {
      const message = `{
        address: ${address},
        timestamp: ${parseInt(Date.now() / 1000)}
      }`;
      signMessage({ message });
    } else {
      open();
    }
  };

  return (
    <>
      {process.env.REACT_APP_BANNER_TEXT && (
        <Navbar fixed="top" className="fixed-navbar">
          <p>
            {process.env.REACT_APP_BANNER_TEXT}
            {process.env.REACT_APP_BANNER_INFO_TOOLTIP_TEXT && (
              <div className="custom-tooltip" style={{ cursor: "pointer" }}>
                {" "}
                <AiOutlineInfoCircle />
                <span className="tooltip-text custom-tooltip-bottom text-start p-2">
                  {process.env.REACT_APP_BANNER_INFO_TOOLTIP_TEXT}
                </span>
              </div>
            )}
          </p>
        </Navbar>
      )}
      <nav
        id="custom-navbar"
        className={`${
          process.env.REACT_APP_BANNER_TEXT && "top-banner"
        } custom-navbar navbar navbar-expand-lg navbar-light float-start w-100`}
      >
        <div className="container px-4">
          <div className="navbar-brand" style={{ cursor: "pointer" }} onClick={goToHome}>
            <img src="images/hyp3-logo.webp" alt="logo" />
          </div>
          <div className="d-flex align-items-center gap-4">
            {pathname !== "/leaderboard" && pathname !== "/claim" && (
              <label className="view-leaderboard-lbl" onClick={() => navigate("/leaderboard?page=1")}>
                View Leaderboard
              </label>
            )}
            {isConnected && !!user ? (
              <button type="button" className="btn active-id-btn">
                {cutAddress(address, 5)}
              </button>
            ) : (
              <button type="button" className="btn connect-btn" onClick={metamaskHandler}>
                {status === "connected" ? "Authenticate" : "Connect Wallet"}
              </button>
            )}
          </div>
        </div>
      </nav>
      <div
        className="offcanvas offcanvas-start mobile-menu-div"
        tabIndex="-1"
        id="mobile-menu"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div className="offcanvas-header">
          <button type="button" className="close-menu btn" data-bs-dismiss="offcanvas" aria-label="Close">
            <BsXCircleFill />
          </button>
        </div>
        <div className="offcanvas-body">
          <div className="head-contact">
            <a href="index.html" className="logo-side">
              <img src="images/8080.png" alt="logo" />
            </a>
            <div className="mobile-menu-sec mt-3">
              <ul className="list-unstyled">
                <li data-bs-dismiss="offcanvas">
                  <NavLink className="nav-link" to="/">
                    Mint
                  </NavLink>
                </li>
                <li data-bs-dismiss="offcanvas">
                  <NavLink className="nav-link" to="/">
                    Stake
                  </NavLink>
                </li>
                <li data-bs-dismiss="offcanvas">
                  <NavLink className="nav-link" to="/">
                    Marketplace
                  </NavLink>
                </li>
                <li data-bs-dismiss="offcanvas">
                  <NavLink to="/" className="btn connect-bnt">
                    Launched Mint <BsArrowRight />
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {show && (
        <Modal className="delete-confirmation" centered show={show} onHide={handleClose}>
          <Modal.Header closeButton className="border-0 pb-0"></Modal.Header>
          <Modal.Body>
            <p style={{ maxWidth: "280px" }}>
              All unsaved changes will be lost. Are you sure you want to <span>leave</span> this page?
            </p>
          </Modal.Body>
          <Modal.Footer className="justify-content-start border-0">
            <button
              onClick={() => {
                navigate("/manage");
                handleClose();
                dispatch(setCurrentFormStep(1));
              }}
              className="btn next-btn m-0"
            >
              Leave
              <span className="d-flex">
                <img src={ArrowRight} alt="Icon" />
              </span>
            </button>
            <button onClick={() => handleClose()} className="btn text-white">
              Cancel
            </button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};
export default Header;
