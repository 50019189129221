import React, { useState, useEffect } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { Slide, ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import { useAccount } from "wagmi";

import Loader from "./assets/Gif/8080laoder.gif";
import closeIcon from "./assets/images/toastClose.png";

import { useAuth } from "./hooks/useAuth";
import Connect from "./pages/connect/Connect";
import Manage from "./pages/manage/Manage";
import BasicConfig from "./pages/register/BasicConfig";
import NotFond from "./pages/404/NotFond";
import Terms from "./pages/terms/Terms";
import Leaderboard from "./pages/leaderboard/Leaderboard";
import Claim from "./pages/claim/Claim";

const AllRoutes = [
  {
    path: "/",
    Component: Connect,
    isPrivate: false,
  },
  {
    path: "/launch",
    Component: BasicConfig,
    isPrivate: true,
  },
  {
    path: "/manage",
    Component: Manage,
    isPrivate: true,
  },
  {
    path: "/claim",
    Component: Claim,
    isPrivate: false,
  },
  {
    path: "/leaderboard",
    Component: Leaderboard,
    isPrivate: false,
  },
  {
    path: "*",
    Component: NotFond,
    isPrivate: false,
  },
];

const AuthContainer = ({ children, isPrivate }) => {
  const navigate = useNavigate();
  const { address, status, isDisconnected, isConnected } = useAccount();
  const { pathname } = useLocation();
  const { logout } = useAuth();

  const [isLoading, setIsLoading] = useState(true);

  const token = localStorage.getItem("token");
  let user = localStorage.getItem("user");
  if (user) {
    user = JSON.parse(user);
  }

  useEffect(() => {
    if ((!isConnected || !user) && isPrivate) {
      navigate("/");
    }
  }, [status, pathname]);

  useEffect(() => {
    if (
      address &&
      token &&
      user &&
      user.wallet_address &&
      user.wallet_address.toLowerCase() !== address.toLowerCase()
    ) {
      logout(isPrivate);
    }
  }, [address]);

  useEffect(() => {
    if (status !== "connecting") {
      if (isPrivate) {
        if (isDisconnected && !token) {
          navigate("/");
        }
      }
      setIsLoading(false);
    }
  }, [status, token]);

  return isLoading ? null : <div>{children}</div>;
};

function App() {
  const loader = useSelector(({ loader }) => loader);
  return (
    <>
      <Routes>
        <Route exact="true" path="/tac" element={<Terms />} />
        {AllRoutes.map(({ Component, path, isPrivate }, i) => (
          <Route
            exact={true}
            path={path}
            key={i}
            element={
              <AuthContainer isPrivate={isPrivate}>
                <Component />
              </AuthContainer>
            }
          />
        ))}
      </Routes>
      <ToastContainer
        position={"top-center"}
        autoClose={3000}
        hideProgressBar={true}
        closeOnClick={true}
        pauseOnHover={true}
        draggable={true}
        closeButton={<img style={{ width: "9px", height: "9px" }} src={closeIcon} />}
        transition={Slide}
      />
      <div
        style={
          !loader
            ? {
                animation: "outAnimation 1000ms ease-out",
                animationFillMode: "forwards",
              }
            : {}
        }
      >
        <div className="custom-loader">
          <img src={Loader} alt="loading..." />
        </div>
      </div>
    </>
  );
}

export default App;
