import React from "react";
import Banner from "./components/Banner";
import HowItWork from "./components/HowItWork";
import Creadibility from "./components/Creadibility";
import Multilogo from "./components/Multilogo";
import "./landing.css";

function Home({ handleConnect, status }) {
  return (
    <>
      <Banner handleConnect={handleConnect} status={status} />
      <section
        className="float-start w-100 body-total-part scrollspy-example"
        data-bs-spy="scroll"
        data-bs-target="#navbar-example2"
        data-bs-offset="100"
        tabIndex="0"
      >
        <div id="howitworks" className="how-it-work-1">
          <HowItWork handleConnect={handleConnect} status={status} />
        </div>
        <div id="credibility" className="credibility-div">
          <Creadibility handleConnect={handleConnect} status={status} />
        </div>
        <Multilogo />
      </section>
    </>
  );
}
export default Home;
