import React, { useEffect } from "react";
import { useAccount, useSignMessage } from "wagmi";
import { useWeb3Modal } from "@web3modal/react";
import { toast } from "react-toastify";

import { useAuth } from "../../hooks/useAuth";
import LandingHeader from "../landing/LandingHeader";
import Home from "../landing/Home";
import Footer from "../../components/Footer";

const Connect = () => {
  document.title = "HYP3 - Simple & Fast Collab Tool | Free Forever";

  const { login } = useAuth();
  const { address, status } = useAccount();
  const { open } = useWeb3Modal();

  const { signMessage } = useSignMessage({
    onSuccess(data, variables) {
      const payload = {
        message: variables.message,
        signature: data,
        wallet_address: address,
      };
      if (payload) {
        login(payload);
      }
    },
    onError() {
      toast.error("Hey, just need to verify it’s you. Please authenticate to proceed.");
    },
  });

  const metamaskHandler = async () => {
    if (status === "connected") {
      const message = `{
        address: ${address},
        timestamp: ${parseInt(Date.now() / 1000)}
      }`;

      signMessage({ message });
    } else {
      open();
    }
  };

  useEffect(() => {
    document.body.style = "background: linear-gradient(184deg, rgba(12, 0, 55, 1) 10%, rgba(55, 0, 55, 1) 55%);";
  }, []);

  return (
    <div className="landing container">
      <LandingHeader handleConnect={metamaskHandler} />
      <Home handleConnect={metamaskHandler} status={status} />
      <Footer />
      <button className="btn install-hyp3-btn manage-install-hyp3">
        <a
          className="d-flex"
          target="_blank"
          href={`${process.env.REACT_APP_DISCORD_AUTH_URL}/oauth2/authorize?client_id=${process.env.REACT_APP_DISCORD_AUTH_CLIENT_ID}&permissions=268435456&scope=bot%20applications.commands`}
        >
          <span className="btn-text">Install HYP3R Bot</span>
          <span className="discord-img">
            <img style={{ maxWidth: "30px" }} src="images/discord-white.png" alt="icon2" />
          </span>
        </a>
      </button>
    </div>
  );
};
export default Connect;
