import { useEffect, useState } from "react";
import { useAccount } from "wagmi";
import bg from "../../assets/images/claimBg.webp";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { ApiCall } from "../../utils/ApiUtils";
import { currencyFormatter } from "../../utils/common";

const Claim = () => {
  const { address, isConnected } = useAccount();
  const [claimToken, setClaimToken] = useState({});

  let user = localStorage.getItem("user");
  if (user) {
    user = JSON.parse(user);
  }

  const getClaimToken = async () => {
    const resp = await ApiCall("GET", `/rest/allowlist-registrants/getCMDataByWalletAddress/${address}`);
    setClaimToken(resp?.data);
  };

  useEffect(() => {
    document.querySelector("body").style.backgroundImage = `url(${bg})`;
    if (address) getClaimToken();
  }, [address]);

  return (
    <>
      <Header />
      <div className="airdrop-section">
        <div className="airdrop-box">
          <div className="drop-title">
            <span>Airdrops coming soon!</span>
          </div>
          {isConnected && !!user && address ? (
            <div className="drop-body">
              <span>You are eligible for</span>
              <p>{currencyFormatter((claimToken?.points || 0) * 10)} $HYP3 TOKENS</p>
              <span className="stay-tune">Stay tuned for more info on airdrop date and other details.</span>
            </div>
          ) : (
            <div className="drop-body">
              <span>Connect and authenticate your wallet to discover your air-drop rewards!</span>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Claim;
