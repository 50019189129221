import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { MdClose } from "react-icons/md";
import { useAccount } from "wagmi";
import Arrow from "../../assets/images/arrow.png";
import { FaBars } from "react-icons/fa";
import { useAuth } from "../../hooks/useAuth";
import "./landing.css";

function LandingHeader({ handleConnect, isTerms }) {
  const [scroll, setScroll] = useState(false);
  const { address, status } = useAccount();
  const { user } = useAuth();

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);

  useEffect(() => {
    if (address || user) {
      localStorage.setItem("token", user?.token);
    } else {
      localStorage.clear();
    }
  }, [address, user, status]);

  const onCkickHandelNew = (event) => {
    document.querySelector(".top-sec-bar").classList.toggle("show-menu1");
    document.querySelector(".navbar-collapse").classList.toggle("hide");
  };

  return (
    <>
      <section className={scroll ? "full-with" : "fixed-with"}>
        <div className="top-sec-bar navbar-fixed-top comon-page float-start w-100">
          <div className="container">
            <div className="top-inside-div">
              <nav className="navbar navbar-expand-lg navbar-light">
                <div className="container-fluid align-items-center">
                  <NavLink to="/" className="navbar-brand">
                    <img src="images/hyp3-logo.webp" alt="logo" style={{ maxWidth: "140px" }} />
                  </NavLink>
                  <button
                    className="navbar-toggler"
                    onClick={onCkickHandelNew}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                  >
                    <span className="bar1">
                      <FaBars />
                    </span>
                    <span className="close1">
                      <MdClose />
                    </span>
                  </button>
                  {!isTerms && (
                    <div className="collapse navbar-collapse hide w-25" id="navbarSupportedContent">
                      <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                          <a href="#howitworks" className="nav-link active">
                            How it works
                          </a>
                        </li>
                        <li className="nav-item">
                          <a href="#credibility" className="nav-link">
                            Credibility
                          </a>
                        </li>
                        <li className="nav-item">
                          <a href="/leaderboard?page=1" className="nav-link">
                            Leaderboard
                          </a>
                        </li>
                        <li className="nav-item">
                          <button
                            type="button"
                            className="btn comon-buuton get-started-bty mx-sm-1"
                            data-bs-toggle="modal"
                            data-bs-target="#productsModal"
                            onClick={handleConnect}
                          >
                            {status === "connected" ? "Authenticate" : "Connect Wallet"}
                            <img src={Arrow} alt="arrow" />
                          </button>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </nav>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default LandingHeader;
